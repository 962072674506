import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Technologies } from 'components/Types';
import clsx from 'clsx';
import styles from './tech.module.css';
import { makeGroup } from '../cases/helpers';

interface SVGProps {
  title: string;
  publicURL: string;
}

interface IMGProps {
  title: string;
  childImageSharp: {
    fluid: {
      src: string;
      srcSet: string;
    };
  };
}

interface Props {
  list: ReadonlyArray<Technologies>;
  listStyle?: string;
}

function RenderSVG({ publicURL, title }: SVGProps) {
  return (
    <img
      src={publicURL}
      alt={title}
      className={clsx(styles.logo, {
        [styles.large]: title === 'amazon' || title === 'googleMeet',
      })}
    />
  );
}

function RenderIMG({ title, childImageSharp }: IMGProps) {
  return (
    <img
      alt={title}
      className={styles.logo}
      src={childImageSharp.fluid.src}
      srcSet={childImageSharp.fluid.srcSet}
    />
  );
}

function Technology({ list, listStyle }: Props) {
  const data = useStaticQuery(graphql`
    query {
      react: file(relativePath: { eq: "react-logo.svg" }) {
        publicURL
      }
      rails: file(relativePath: { eq: "rails-logo.svg" }) {
        publicURL
      }
      nodejs: file(relativePath: { eq: "nodejs-logo.svg" }) {
        publicURL
      }
      angular: file(relativePath: { eq: "angular-logo.svg" }) {
        publicURL
      }
      reactNative: file(relativePath: { eq: "react-native-logo.svg" }) {
        publicURL
      }
      reactNativeWide: file(relativePath: { eq: "react-native.svg" }) {
        publicURL
      }
      blockchain: file(relativePath: { eq: "blockchain-logo.svg" }) {
        publicURL
      }
      hyperledger: file(relativePath: { eq: "hyperledger-logo.svg" }) {
        publicURL
      }
      figma: file(relativePath: { eq: "figma-logo.svg" }) {
        publicURL
      }
      notion: file(relativePath: { eq: "notion-logo.svg" }) {
        publicURL
      }
      amazon: file(relativePath: { eq: "amazon-logo.svg" }) {
        publicURL
      }
      gatsby: file(relativePath: { eq: "gatsby-logo.svg" }) {
        publicURL
      }
      skype: file(relativePath: { eq: "skype-logo.svg" }) {
        publicURL
      }
      zoom: file(relativePath: { eq: "zoom-logo.svg" }) {
        publicURL
      }
      googleMeet: file(relativePath: { eq: "google-meet-logo.svg" }) {
        publicURL
      }
      postgreSQL: file(relativePath: { eq: "postgre-sql-logo.svg" }) {
        publicURL
      }
      aws: file(relativePath: { eq: "aws-logo.svg" }) {
        publicURL
      }
      nextJs: file(relativePath: { eq: "next-js.svg" }) {
        publicURL
      }
      cloudFront: file(relativePath: { eq: "cloud-front.svg" }) {
        publicURL
      }
      nest: file(relativePath: { eq: "nest.svg" }) {
        publicURL
      }
      lambda: file(relativePath: { eq: "aws-lambda.svg" }) {
        publicURL
      }
      mongo: file(relativePath: { eq: "mongo-logo.svg" }) {
        publicURL
      }
      expressJs: file(relativePath: { eq: "express-js-logo.svg" }) {
        publicURL
      }
      azure_aws: file(relativePath: { eq: "azure_aws.svg" }) {
        publicURL
      }
      azure: file(relativePath: { eq: "azure.svg" }) {
        publicURL
      }
      google_cloud_platform: file(relativePath: { eq: "gcp.svg" }) {
        publicURL
      }
      selenium: file(relativePath: { eq: "selenium.svg" }) {
        publicURL
      }
      zephyr_scale: file(relativePath: { eq: "zephyr_scale.svg" }) {
        publicURL
      }
      blue_ocean: file(relativePath: { eq: "blue-ocean.svg" }) {
        publicURL
      }
      jenkins: file(relativePath: { eq: "jenkins.svg" }) {
        publicURL
      }
      digital_ocean: file(relativePath: { eq: "digital-ocean.svg" }) {
        publicURL
      }
      ruby: file(relativePath: { eq: "career/ruby-logo.svg" }) {
        publicURL
      }
      python: file(relativePath: { eq: "python.svg" }) {
        publicURL
      }
      vue: file(relativePath: { eq: "vuejs.svg" }) {
        publicURL
      }
      tailwind: file(relativePath: { eq: "tailwind.svg" }) {
        publicURL
      }
      stripe: file(relativePath: { eq: "stripe.svg" }) {
        publicURL
      }
      strapi: file(relativePath: { eq: "strapi.svg" }) {
        publicURL
      }
    }
  `);

  const tech = React.useMemo(() => {
    const {
      react,
      rails,
      angular,
      nodejs,
      reactNative,
      blockchain,
      hyperledger,
      figma,
      notion,
      amazon,
      gatsby,
      skype,
      zoom,
      googleMeet,
      postgreSQL,
      aws,
      nextJs,
      cloudFront,
      nest,
      lambda,
      mongo,
      expressJs,
      azure_aws,
      blue_ocean,
      jenkins,
      digital_ocean,
      ruby,
      python,
      vue,
      zephyr_scale,
      azure,
      google_cloud_platform,
      selenium,
      reactNativeWide,
      tailwind,
      stripe,
      strapi,
    } = data;
    return {
      react: { title: 'react', ...react },
      rails: { title: 'rails', ...rails },
      nodejs: { title: 'nodejs', ...nodejs },
      angular: { title: 'angular', ...angular },
      reactNative: { title: 'reactNative', ...reactNative },
      blockchain: { title: 'blockchain', ...blockchain },
      hyperledger: { title: 'hyperledger', ...hyperledger },
      figma: { title: 'figma', ...figma },
      notion: { title: 'notion', ...notion },
      amazon: { title: 'amazon', ...amazon },
      gatsby: { title: 'gatsby', ...gatsby },
      skype: { title: 'skype', ...skype },
      zoom: { title: 'zoom', ...zoom },
      googleMeet: { title: 'googleMeet', ...googleMeet },
      postgreSQL: { title: 'postgreSQL', ...postgreSQL },
      aws: { title: 'aws', ...aws },
      nextJs: { title: 'nextJs', ...nextJs },
      cloudFront: { title: 'cloudFront', ...cloudFront },
      nest: { title: 'nest', ...nest },
      lambda: { title: 'lambda', ...lambda },
      mongo: { title: 'mongo', ...mongo },
      expressJs: { title: 'expressJs', ...expressJs },
      azure_aws: { title: 'azure_aws', ...azure_aws },
      blue_ocean: { title: 'blue_ocean', ...blue_ocean },
      jenkins: { title: 'jenkins', ...jenkins },
      digital_ocean: { title: 'digital_ocean', ...digital_ocean },
      ruby: { title: 'ruby', ...ruby },
      python: { title: 'python', ...python },
      vue: { title: 'vue', ...vue },
      zephyr_scale: { title: 'zephyr_scale', ...zephyr_scale },
      azure: { title: 'azure', ...azure },
      google_cloud_platform: { title: 'google_cloud_platform', ...google_cloud_platform },
      selenium: { title: 'selenium', ...selenium },
      reactNativeWide: { title: 'reactNativeWide', ...reactNativeWide },
      tailwind: { title: 'tailwind', ...tailwind },
      stripe: { title: 'stripe', ...stripe },
      strapi: { title: 'strapi', ...strapi },
    };
  }, []);

  return (
    <div className={clsx(styles.list, listStyle)}>
      {list.map((e) => {
        if ('publicURL' in tech[e]) {
          return (
            <RenderSVG key={tech[e].title} title={tech[e].title} publicURL={tech[e].publicURL} />
          );
        }
        if ('childImageSharp' in tech[e]) {
          return (
            <RenderIMG
              key={tech[e].title}
              title={tech[e].title}
              childImageSharp={tech[e].childImageSharp}
            />
          );
        }
      })}
    </div>
  );
}

interface TechnologyGroupProps {
  technology: ReadonlyArray<Technologies>;
}

export function TechnologyGroup({ technology }: TechnologyGroupProps) {
  const t = React.useMemo(() => makeGroup(technology, 2), [technology]);

  return (
    <div className={styles.listWrapper}>
      {t.map((e, i) => (
        <Technology key={i.toString()} list={e} />
      ))}
    </div>
  );
}

export default Technology;
